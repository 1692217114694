import React from 'react';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import Accomudation from "./components/Accomudation"
import ScrollToTop from './components/ScrollToTop';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/i18n.js';


function App() {
  return (
      <I18nextProvider i18n={i18n}>
    <div className="App">
        <div>
          <Header />
        </div>
        <main>
          <section id="about-us">
            <AboutUs />
          </section>
          <section id="accomudation">
            <Accomudation />
          </section>
          <section id="contact">
            <Contacts />
          </section>
        </main>
        <Footer />
    <ScrollToTop/>
    </div>
    </I18nextProvider>
  );
}

export default App;
