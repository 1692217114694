import React, { useState } from 'react';
import styles from './ImageGallery.module.css'; // Import CSS module for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const ImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className={styles['image-gallery-container']}>
      <button onClick={prevImage} className={styles['prev-button']}><p><FontAwesomeIcon icon={faAngleLeft} style={{color: "#FFF",}} /></p></button>
      <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex}`} className={styles['gallery-image']} />
      <button onClick={nextImage} className={styles['next-button']}><FontAwesomeIcon icon={faAngleRight} style={{color: "#FFF",}} /></button>
    </div>
  );
};

export default ImageGallery;
