import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from "./Contacts.module.css"

function Contacts() {
  const { t } = useTranslation();

  return (
    <div id="contact" className="contact-section">
      <h2>{t("contact")}</h2>
      <div className={styles["contact-info"]}>
      <p><FontAwesomeIcon icon={faPhone} style={{color: "var(--text-color)",}} /><strong>{t("phone")}:</strong> +351 912 345 678</p>
      <p><FontAwesomeIcon icon={faEnvelope} style={{color: "var(--text-color)",}} /><strong>Email:</strong> cruzvelha2008@hotmail.com</p>
      </div>
      <div className="map-container"> <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3569.42761570365!2d-7.443530225898007!3d41.21046153910051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3b4347216b0203%3A0x35d4799ed94154b3!2sQuinta%20da%20Coutada!5e0!3m2!1spt-PT!2sus!4v1715776341004!5m2!1spt-PT!2sus" width="100%" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0" title="Location" ></iframe>
      </div>
    </div>
  );
}

export default Contacts;
