import React from 'react';
import { useTranslation } from 'react-i18next';
import { airbnb_url, booking_url } from '../config';
import styles from "./Reservation.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faHome } from '@fortawesome/free-solid-svg-icons';

import booking_img from '../assets/booking_logo.png';
import airbnb_img from '../assets/airbnb_logo.png';


const Reservation = () => {
  const { t } = useTranslation();

  return (
    <div id="reservation" className={styles["reservation-container"]}>
      <h2>{t('reservationTitle')}:</h2>
      <div className={styles["buttons"]}>
        <button className={styles["booking-btn"]}>
          <a href={booking_url} target="_blank" rel="noopener noreferrer">
            Booking.com
            <img src={booking_img} className={styles["icon"]} />
          </a>
        </button>
        <button className={styles["airbnb-btn"]}>
          <a href={airbnb_url} target="_blank" rel="noopener noreferrer">
            Airbnb
            <img src={airbnb_img} className={styles["icon"]} />
          </a>
        </button>
      </div>
    </div>
  );
};

export default Reservation;
