import React from 'react';
import { useTranslation } from 'react-i18next';
import ServicesList from '../components/ServicesList.js';
import Reservation from './Reservation.js';
import styles from "./Accomudation.module.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageGallery from './ImageGallery.js';



import quarto_1 from '../assets/casa/quarto_1.jpg';
import quarto_2 from '../assets/casa/quarto_2.jpg';
import quarto_3 from '../assets/casa/quarto_3.jpg';
import casa_banho_1 from '../assets/casa/casa_banho_1.jpg';
import casa_banho_2 from '../assets/casa/casa_banho_2.jpg';
import casa_banho_3 from '../assets/casa/casa_banho_3.jpg';
import varanda_1 from '../assets/casa/varanda_1.jpg';
import vista_1 from '../assets/casa/vista_1.jpg';
import vista_2 from '../assets/casa/vista_2.jpg';
import cozinha_1 from '../assets/casa/cozinha_1.jpg';
import cozinha_2 from '../assets/casa/cozinha_2.jpg';

const images = [quarto_1, quarto_2, quarto_3, casa_banho_1, casa_banho_2, casa_banho_3, varanda_1, vista_1, vista_2, cozinha_1, cozinha_2]



const Accomudation = () => {
  const { t } = useTranslation();

  return (
    <div id="accommodation" className={styles["accommodation-div"]}>
      <h2>{t("accommodation")}</h2>

      <p>
        Experience the tranquility of our farm by staying in our comfortable and luxurious accommodations. We offer
        a variety of options to suit your needs.
      </p>

      <ImageGallery images={images}/> 

      <Reservation/>
    
      {/* <div className={styles["accomudationList"]}> <ServicesList services={services}/> </div> */}
    </div>
  );
};

export default Accomudation;
