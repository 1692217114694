import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div id="about-us">
      <h2>{t("aboutUs")}</h2>
      <p> {t("accomodationText.text1")} </p>
      <p> {t("accomodationText.text2")} </p>
      <p> {t("accomodationText.text3")} </p>
    </div>
  );
};

export default AboutUs;
