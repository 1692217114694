// Header.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageFluid from '../components/ImageFluid';
import styles from "./Header.module.css";
import LanguageSelector from '../components/LanguageSelector'; // Import the LanguageSelector component

function Header() {
  const { t } = useTranslation();

  const handleNavigation = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
    <div className={styles["header-div"]}>
      <nav>
        <ul>
          <li> <a href="#about-us" onClick={handleNavigation}>{t('aboutUs')}</a> </li>
          <li> <a href="#accommodation" onClick={handleNavigation}>{t('accommodation')}</a> </li>
          <li> <a href="#contact" onClick={handleNavigation}>{t('contact')}</a> </li>
          <li> <LanguageSelector /> </li>
        </ul>
      </nav>
      <ImageFluid />
    </div>

    </>
    
  );
}

export default Header;
