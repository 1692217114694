import React from 'react';
import image1 from '../assets/bg_image.jpg';
import styles from './ImageFluid.module.css';

function ImageFluid() {
  return (
    <div className={styles.container}>
      <img src={image1} className={styles.image} alt="background" />
      <h1 className={`${styles.text} ${styles['text-stroke']}`}>Quinta da Coitada</h1>
    </div>
  );
}

export default ImageFluid;
