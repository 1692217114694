import React from 'react';
import styles from "./Footer.module.css"

const Footer = () => {
  return (
    <footer className={styles["footer"]}>
      <p>&copy; 2024 Quinta da Coitada. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
