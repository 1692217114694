import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GB as FlagUK, PT as FlagPT } from 'country-flag-icons/react/3x2'; // Import flag icons
import styles from './LanguageSelector.module.css'; // Import the CSS file for styling

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', name: 'English', flag: <FlagUK title="Great Britain" style={{ width: '25px', height: '25px' }} /> },
    { code: 'pt', name: 'Portuguese', flag: <FlagPT title="Portugal" style={{ width: '25px', height: '25px' }} /> },
    // Add more languages as needed
  ];

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  return (
    <div className={styles["language-selector"]}>
      <div className={`${styles["selected-language"]} ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles["language-option"]}>
          {i18n.language === 'en' ? (
            <FlagUK title="Great Britain" style={{ width: '25px', height: '25px' }} />
          ) : (
            <FlagPT title="Portugal" style={{ width: '25px', height: '25px' }} />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles["language-options"]}>
          {languages.map(lang => (
            <div
              key={lang.code}
              className={`${styles["language-option"]} ${i18n.language === lang.code ? 'active' : ''}`}
              onClick={() => changeLanguage(lang.code)}
            >
              <div className={styles["language-item"]}>
                {lang.flag}
                <span className={styles["language-name"]}>{lang.name}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
